'use client';
import { cn } from '@/lib/utils';
import { AnimatePresence, motion, useMotionValue, useScroll, useTransform, Variants } from 'motion/react';
import { useLenis } from 'lenis/react';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import { useEffect, useMemo, useRef, useState } from 'react';
import { HiOutlineChevronDoubleDown } from 'react-icons/hi';
import AkGirlsCz from '../svg/AkgirlsCz';
import React from 'react';

const variants = {
    hidden: {
        opacity: 0,
        x: -100
    },
    visible: {
        opacity: 1,
        x: 0
    }
} as Variants;

const HeroSection = () => {
    const t = useTranslations('landingPage');
    const texts = useMemo(
        () => [t('intro.newSocialNetwork'), t('intro.czechBased'), t('intro.meetRealPeople'), t('intro.verifiedGirls'), t('intro.noFakeProfiles'), t('intro.realInteractions')],
        [t]
    );

    const lenis = useLenis();
    const [text, setText] = useState<string>(texts[0]);
    const [textAnimationDone, setTextAnimationDone] = useState<boolean>(false);
    const sectionRef = useRef<HTMLDivElement>(null);
    // const [ref, { width }] = useMeasure();
    const { scrollYProgress } = useScroll({
        target: sectionRef,
        offset: ['start end', 'end start']
    });
    const y = useTransform(scrollYProgress, [0, 1], ['-150%', '100%']);
    const xTranslation = useMotionValue(0);

    useEffect(() => {
        let i = 1;
        let interval: NodeJS.Timeout | undefined;
        const timeout = setTimeout(() => {
            setTextAnimationDone(true);
        }, 1000);
        interval = setInterval(() => {
            setText(texts[i]);
            i++;
            if (i === texts.length) {
                i = 0;
            }
        }, 3000);

        return () => {
            interval ? clearInterval(interval) : null;
            clearTimeout(timeout);
        };
    }, []);

    return (
        <>
            <div
                ref={sectionRef}
                style={{
                    minHeight: '100dvh',
                    height: '-webkit-fill-available',
                    maxHeight: '100dvh'
                }}
                className={cn('relative overflow-hidden')}>
                <motion.div className="absolute inset-0 -z-10 h-[150%]" style={{ top: y }}>
                    <video autoPlay muted loop playsInline controls={false} className="absolute inset-0 h-full w-full object-cover">
                        <source src="/landing/herovid.mp4" />
                        <Image src="/landing/hero.jpg" alt="hero" fill className="h-full w-full object-cover" />
                    </video>
                </motion.div>
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                <div className="absolute inset-0 mx-auto flex max-w-4xl flex-col items-center justify-center gap-3 px-8 lg:px-20">
                    <motion.h6
                        variants={variants}
                        initial={{
                            opacity: 0,
                            x: -100,
                            rotate: 15
                        }}
                        animate={{
                            rotate: 0,
                            opacity: 1,
                            x: 0
                        }}
                        transition={{
                            delay: 0.5
                        }}
                        className="defaultGradient textGradient gradientAnimation z-30 self-end text-right text-2xl font-extrabold uppercase text-transparent">
                        {t('intro.newSocialNetwork')}
                    </motion.h6>
                    <AkGirlsCz fill="white" stroke="none" width={'100%'} variants={variants} initial="hidden" animate="visible" />

                    <motion.div initial="hidden" animate="visible" variants={variants} transition={{ delay: 1.5 }} className="self-start">
                        <AnimatePresence mode="sync">
                            <div key={`waper_${text}`} className="absolute w-fit">
                                <motion.p
                                    key={text}
                                    className="w-fit origin-center text-nowrap text-lg"
                                    initial={{
                                        opacity: 0,
                                        scale: 0.5
                                    }}
                                    animate={{
                                        opacity: 1,
                                        scale: 1
                                    }}
                                    exit={{
                                        opacity: 0,
                                        scale: 0
                                    }}>
                                    {text}
                                </motion.p>
                                <motion.div
                                    initial={{ width: 0, height: '3px' }}
                                    animate={{
                                        width: '100%',
                                        transition: {
                                            duration: 3,
                                            delay: textAnimationDone ? 0 : 1.5
                                        }
                                    }}
                                    exit={{ width: 0, transition: { duration: 0.1 } }}
                                    key={`border_${text}`}
                                    className="defaultGradient rounded-full bg-primary"
                                />
                            </div>
                        </AnimatePresence>
                    </motion.div>
                    <motion.div
                        initial={{
                            y: 100,
                            opacity: 0
                        }}
                        animate={{
                            y: 0,
                            opacity: 1
                        }}
                        transition={{
                            delay: 4.5,
                            type: 'spring'
                        }}
                        className="absolute inset-x-0 bottom-3 flex w-full cursor-pointer flex-col items-center justify-center gap-2">
                        <motion.div
                            onClick={() => {
                                lenis?.scrollTo('#explore', {
                                    offset: -65,
                                    duration: 3.5
                                });
                            }}
                            className="flex flex-col items-center justify-center"
                            initial={{
                                y: 0
                            }}
                            animate={{
                                y: -10
                            }}
                            transition={{
                                repeat: Infinity,
                                repeatType: 'reverse',
                                stiffness: 500,
                                type: 'spring'
                            }}>
                            <span className="font-thin uppercase tracking-widest">{t('intro.moreInfo')}</span>
                            <HiOutlineChevronDoubleDown size={50} />
                        </motion.div>
                    </motion.div>
                </div>
            </div>
        </>
    );
};

export default HeroSection;
