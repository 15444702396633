'use client';
import React, { useEffect } from 'react';
import { AnimatePresence, motion, useInView, useMotionValue, useMotionValueEvent, useScroll, useSpring, useTransform } from 'motion/react';
import MaxWidthWrapper from '../MaxWidthWrapper';
import { Separator } from '../ui/separator';
import AkGirlsCz from '../svg/AkgirlsCz';
import { useWindowSize } from '@uidotdev/usehooks';
import { useTranslations } from 'next-intl';

const HowItWorksSection = () => {
    const t = useTranslations('landingPage');
    const [activeStep, setActiveStep] = React.useState<number>(0);
    const wrapperRef = React.useRef<HTMLDivElement>(null);
    const isInView = useInView(wrapperRef);
    const width = useWindowSize().width ?? 0;
    const { scrollYProgress } = useScroll({
        target: wrapperRef,
        offset: ['start end', 'end start']
    });
    useMotionValueEvent(scrollYProgress, 'change', latest => {
        if (latest < 0.3) {
            setActiveStep(0);
        } else if (latest >= 0.3 && latest < 0.5) {
            setActiveStep(1);
        } else {
            setActiveStep(2);
        }
    });
    const x = useTransform(scrollYProgress, [0, 1], ['-250%', '155%']);
    const Steps = [
        {
            id: 1,
            title: t('howItWorks.registration.title'),
            description: t('howItWorks.registration.description')
        },
        {
            id: 2,
            title: t('howItWorks.getAccess.title'),
            description: t('howItWorks.getAccess.description')
        },
        {
            id: 3,
            title: t('howItWorks.chat.title'),
            description: t('howItWorks.chat.description')
        }
    ];
    return (
        <motion.div className="relative overflow-hidden py-10">
            <MaxWidthWrapper>
                <h2 className="mb-10 text-center text-4xl font-bold">{t('howItWorks.title')}</h2>
                <AnimatePresence>
                    <motion.section ref={wrapperRef} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} className="relative flex flex-col items-center justify-center">
                        <div className="grid grid-cols-1 gap-10 xl:grid-cols-3">
                            {Steps.map((step, index) => (
                                <motion.div
                                    key={step.id}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ delay: index * 0.2 }}
                                    className="relative flex aspect-square max-h-96 w-full flex-col items-center justify-center gap-2 px-20">
                                    <AnimatePresence mode="wait">
                                        {activeStep === index && (
                                            <div className="absolute h-full w-full">
                                                <svg className="absolute h-full w-full">
                                                    <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
                                                        <stop offset="0%" stopColor="#0016FF"></stop>
                                                        <stop offset="50%" stopColor="#006E9E"></stop>
                                                        <stop offset="100%" stopColor="#0016FF"></stop>
                                                    </linearGradient>
                                                    <motion.circle
                                                        cx="50%"
                                                        cy="50%"
                                                        r="49%"
                                                        fill="url(#linearColors)"
                                                        stroke="white"
                                                        strokeWidth="4"
                                                        initial={{
                                                            pathLength: 0,
                                                            opacity: 0
                                                        }}
                                                        animate={
                                                            isInView && {
                                                                pathLength: 1,
                                                                opacity: 1
                                                            }
                                                        }
                                                        exit={{
                                                            opacity: 0
                                                        }}
                                                        transition={{
                                                            opacity: {
                                                                duration: 0.5
                                                            },
                                                            duration: 4.5
                                                        }}
                                                    />
                                                </svg>
                                                {/* <motion.div layoutId="circle" className="border-2 border-primary bg-black w-full h-full rounded-full top-0 left-0 absolute" /> */}
                                            </div>
                                        )}
                                    </AnimatePresence>
                                    <div className="z-50 space-y-5 text-center">
                                        <span className="flex items-center justify-center gap-2">
                                            <h1 className="font-sans text-5xl font-extrabold text-primary-text">{step.id}</h1>
                                            <h3 className="font-sans text-xl font-bold">{step.title}</h3>
                                        </span>
                                        <Separator />
                                        <p className="text-center" dangerouslySetInnerHTML={{ __html: step.description }}></p>
                                    </div>
                                </motion.div>
                            ))}
                        </div>
                    </motion.section>
                </AnimatePresence>
            </MaxWidthWrapper>
            <motion.div className="absolute top-[80%] h-full w-[350%] -translate-y-1/2 rotate-12" style={{ left: x }}>
                <AkGirlsCz fill="hsl(var(--primary) / 0.08)" className="h-full w-full" />
            </motion.div>
            {/* <motion.h1
                style={{ left: x }}
                className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-9xl text-primary-text/10 font-black tracking-widest -rotate-12 scale-150">
                AKGIRLS.CZ
            </motion.h1> */}
        </motion.div>
    );
};

export default HowItWorksSection;
